import { RefObject, useEffect, useId, useRef, useState } from 'react';

import { Swiper } from 'swiper';
import {
  Autoplay as SwiperAutoplay,
  EffectFade as SwiperEffectFade,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import { useGlobal } from '@hultafors/snickers/hooks';
import {
  SliderBlockFragment,
  SliderHeroFragment,
} from '@hultafors/snickers/types';

import { HeroBlock } from '../hero-block/hero-block';

import ChevronSvg from './chevron.svg';
import {
  ArrowBtn,
  ArrowNavigator,
  DotBtn,
  DotNavigator,
  DotNavigatorInner,
  SliderBlockStyled,
  SwiperContainer,
  SwiperWrapper,
} from './slider-block.styled';

require('swiper/css');
require('swiper/css/navigation');

const delayFactor = 1000;

interface SliderBlockProps extends SliderBlockFragment {
  priority?: boolean;
}

export const SliderBlock: React.FC<SliderBlockProps> = ({
  arrows,
  dots,
  delay: initialDelay,
  heroes,
  slideAnimation,
  title,
  priority,
}) => {
  const uid = useId();
  const { global } = useGlobal();
  const swiper = useRef<Swiper | null>(null);
  const swiperElement = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Check if Slide Animation is checked
  // If not, include Fade Module
  const SwiperModules = [SwiperAutoplay, SwiperNavigation, SwiperPagination];
  let shouldUseSlideAnimation = 'fade';
  if (slideAnimation) {
    shouldUseSlideAnimation = 'slide';
    SwiperModules.push(SwiperEffectFade);
  }

  function initializeSwiper(element: RefObject<HTMLDivElement>) {
    if (element.current && !swiper.current) {
      swiper.current = new Swiper(element.current, {
        modules: SwiperModules,
        effect: shouldUseSlideAnimation,
        direction: 'horizontal',
        speed: 800,
        loop: true,
        autoplay: {
          delay: initialDelay * delayFactor,
          disableOnInteraction: false,
        },
        pagination: {
          el: paginationRef.current,
          type: 'bullets',
          clickable: true,
        },
        allowTouchMove: true,
        on: {
          realIndexChange: (target) => {
            setCurrentIndex(target.realIndex);
          },
        },
      });
    }
  }

  useEffect(() => {
    initializeSwiper(swiperElement);
  }, []);

  function heroMapper(hero: SliderHeroFragment, index: number) {
    const priority = index === 0;
    return (
      <div className="swiper-slide" key={`SliderBlock-${uid}-${hero.id}`}>
        <HeroBlock
          id={hero.id}
          title={hero.title}
          doubleColorTitle={hero.doubleColorTitle}
          description={hero.description}
          heroIcon={hero.heroIcon}
          ctaText={hero.ctaText}
          ctaLink={hero.ctaLink}
          mobileImage={hero.mobileImage}
          tabletImage={hero.tabletImage}
          image={hero.image}
          priority={priority}
          secondCtaLink={hero.secondCtaLink}
          secondCtaText={hero.secondCtaText}
          thirdCtaLink={hero.thirdCtaLink}
          thirdCtaText={hero.thirdCtaText}
        />
      </div>
    );
  }

  function paginationDotsMapper(_hero: SliderHeroFragment, index: number) {
    function onClick() {
      swiper.current?.slideToLoop(index);
    }
    return (
      <DotBtn
        key={`Pagination-button-${index}`}
        $active={currentIndex === index}
        onClick={onClick}
        aria-label={`${global?.sliderNavigationLabel} ${index + 1}`}
      >
        <span />
      </DotBtn>
    );
  }

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }

  return (
    <SliderBlockStyled data-test-id="SliderBlock">
      <SwiperContainer className="swiper" ref={swiperElement}>
        <SwiperWrapper className="swiper-wrapper">
          {heroes.map(heroMapper)}
        </SwiperWrapper>
      </SwiperContainer>
      {arrows && (
        <ArrowNavigator>
          <ArrowBtn
            aria-label={global?.previousLabel || ''}
            ref={prevRef}
            onClick={slidePrev}
          >
            <ChevronSvg />
          </ArrowBtn>
          <ArrowBtn
            aria-label={global?.nextLabel || ''}
            ref={nextRef}
            onClick={slideNext}
          >
            <ChevronSvg />
          </ArrowBtn>
        </ArrowNavigator>
      )}
      {dots && (
        <DotNavigator>
          <DotNavigatorInner>
            {heroes.map(paginationDotsMapper)}
          </DotNavigatorInner>
        </DotNavigator>
      )}
    </SliderBlockStyled>
  );
};
